.table-component {
  margin-top: 4em;
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
  .leagues-logo {
    height: 100px;
    width: 100px;
  }
  .flag {
    height: 40px;
    width: 40px;
    border-radius: 100%;
  }
}
.search-box {
  margin-top: 2em;
  margin-bottom: 2em;
  .ant-input-suffix {
    margin-top: -4.5px !important;
  }
}