.league-logo {
  height: 35px;
  width: 35px;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302),
    0 2px 6px 2px rgba(60, 64, 67, 0);
}
.loader {
  font-size: 50px;
  display: flex;
  justify-content: center;
  margin-top: 2em;
}

.loader-button {
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.loader-button-black {
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
}
