.vs-div {
    background-color: #ebecf0;
    height: 35px;
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    font-size: 13px;
    font-weight: bold;
      margin-right: 3em;
  }
  .home-team {
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    //   border: 1px solid black;
    padding: 5px;
  }
  .away-team {
    display: flex;
    align-items: center;
    width: 150px;
    height: 50px;
    //   border: 1px solid black;
    padding: 5px;
  }
  
  .flag {
    height: 30px;
    width: 30px;
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302),
      0 2px 6px 2px rgba(60, 64, 67, 0);
  }
  
  .team-name {
    font-size: 15px;
    font-weight: 600;
    margin-left: 7px;
    font: bold;
  }
  
  .fixture-detail-span {
    font-weight: bold;
    margin-right: 5px;
  }
  