.auth-button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 30px;
}

.error-message {
  font-size: 12px;
  color: red;
}

.label-message {
  text-align: center;

  label {
    margin-bottom: 0;
  }
}
.auth-modal {
  .ant-modal-content {
    border-radius: 15px;
  }
  .ant-form-item {
    width: 70%;
    margin: 10px auto;
  }
  .ant-form-item-control-input-content {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  .ant-modal {
    width: 450px !important;
  }
}
