.header {
  height: 5em;
  background-color: #ebecf0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  vertical-align: middle;
  h3 {
    color: black;
  }
}
