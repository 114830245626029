.detail {
  margin-left: 5px;
  font-size: 15px;
  margin-top: 3px;
}

.plus-icon {
  margin-right: 7px !important;
  font-size: 17px !important;
  color: rgba(0, 0, 0, 0.65) !important;
  margin-top: 3px !important;
}

.label-restaurant-detail {
  font-weight: bold;
  margin-bottom: 3px !important;
  color: #000000a1 !important;
}

.stadium-detail-label {
  color: rgba(0, 0, 0, 0.85) !important;
  margin-right: 5px;
  font-size: 16px;
  font-weight: 500 !important;
}

.review-detail {
  margin-left: 5px;
}

.review-label {
  font-weight: bold !important;
}
.stadium-img {
  height: 300px;
  width: 300px;
}

.stadium-detail-logo {
  height: 25px;
  width: 25px;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302),
    0 2px 6px 2px rgba(60, 64, 67, 0);
  margin-right: 5px;
}

.loader-button {
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.login-img-block {
  img {
    height: 110px;
    width: 300px !important;
  }
}
.login-main {
  .ant-card {
    margin: 25px auto;
    width: 50%;
  }
}

/* Default styles for larger screens */
.login-img-block img {
  height: 90px;
  width: 250px !important;
}

.login-main .ant-card {
  margin: 25px auto;
  width: 50%;
}
.error-message {
  color: red;
}

@media (max-width: 768px) {
  .login-img-block img {
    height: auto;
    width: 100%;
  }

  .login-main .ant-card {
    width: 90%;
    margin: 15px auto;
  }
}
