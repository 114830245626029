.ant-table-row-expand-icon {
  outline: none !important;
}
.comment {
  font-weight: 600;
  margin: 2px;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.85);
}

.rating-categories {
  display: flex;
  justify-content: space-between;
  h6 {
    font-size: 16px;
  }
}
.label {
  font-weight: bold;
  font-size: 14px;
}

.connect-button {
  margin-top: 1em;
}

.status-block {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  &__approve-button {
    background: rgb(135, 208, 104);
    border-color: rgb(135, 208, 104);
  }

  &__approve-button:hover {
    background: rgb(135, 208, 104);
    border-color: rgb(135, 208, 104);
  }

  &__approve-button:focus {
    background: rgb(135, 208, 104);
    border-color: rgb(135, 208, 104);
  }

  &__decline-button {
    background: rgb(255, 85, 0);
    border-color: rgb(255, 85, 0);
  }

  &__decline-button:hover {
    background: rgb(255, 85, 0);
    border-color: rgb(255, 85, 0);
  }

  &__decline-button:focus {
    background: rgb(255, 85, 0);
    border-color: rgb(255, 85, 0);
  }
}
.fav-teams {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;

  .fav-teams-tag{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding: 7px 10px;
    border-radius: 7px;
    background-color: rgba(116, 111, 111, 0.1);

    p{
      margin: 0;
    }

    img{
      width: 15px;
      height: 15px;
      border-radius: 100%;
    }
  }
}