.about {
  font-weight: 600;
  margin-right: 5px;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.85);
}

.rating-categories {
  display: flex;
  justify-content: space-between;
  h6 {
    font-size: 16px;
  }
}

.star-ratings {
  display: flex !important;
}

// .star-container {
//   padding-left: 0px !important;
//   padding-right: 3px !important;
//   svg {
//     height: 25px !important;
//     width: 25px !important;
//   }
// }
.connect-link {
  padding: 0px !important;
}

.ant-select-arrow {
  top: 28% !important;
}

.status-block {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  &__approve-button {
    background: rgb(135, 208, 104);
    border-color: rgb(135, 208, 104);
  }

  &__approve-button:hover {
    background: rgb(135, 208, 104);
    border-color: rgb(135, 208, 104);
  }

  &__approve-button:focus {
    background: rgb(135, 208, 104);
    border-color: rgb(135, 208, 104);
  }

  &__decline-button {
    background: rgb(255, 85, 0);
    border-color: rgb(255, 85, 0);
  }

  &__decline-button:hover {
    background: rgb(255, 85, 0);
    border-color: rgb(255, 85, 0);
  }

  &__decline-button:focus {
    background: rgb(255, 85, 0);
    border-color: rgb(255, 85, 0);
  }
}

.user-origin{
  text-align: center;
  margin-bottom: 10px;
}